export default {
  "app": {
    "AppBar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit Form"])},
    "LanguajeButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languaje"])},
    "GroupLabels": {
      "SurgeryAndPregnancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surgery or Pregnancy"])},
      "Group1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clotting disorder, thrombosis, diabetes, high blood pressure or epilepsy"])},
      "Group2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heart, lung, eye or digestive system problems"])},
      "Group3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent infections (moderate to severe), sleep problems or allergies"])}
    },
    "Labels": {
      "PersonalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information"])},
      "MedInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Information"])},
      "MedicalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The patient present or has presented in the last 6 months of the following"])},
      "CurrentMedication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use of current medication"])},
      "Attach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Documents"])},
      "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "DataSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information saved successfully"])},
      "MessageDataSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data will be sent to the International SOS's the medical staff"])},
      "DataAuthorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization for Personal Data"])},
      "DataConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I authorize the processing of my personal data and sensitive personal data by Sociedad Minera Cerro Verde S.A.A. and International SOS, and I understand that I can exercise my rights to access, rectify, cancel, and oppose at any time by sending an email to SM-CVE-datospersonales", "@", "fmi.com and DL.SUP.ASIS", "@", "international.com. For more information, please refer to the privacy policy available on our website https://www.cerroverde.pe and the following "])},
      "AtttachDocumentMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We've identified that you've selected a condition that requires evaluation by Sermedi's medical department, for which we kindly ask you to attach the updated medical report or evidence of medical treatment (it can be a photo)."])},
      "Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["link"])},
      "SwornStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The information declared in this questionnaire will be considered as a sworn statement."])},
      "BMI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Body Mass Index"])},
      "BMIMessage1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No observations related to obesity"])},
      "BMIMessage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical reports must be attached for review by the Medical Department"])},
      "BMIMessage21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Cardiology report and stress test, dated within the last 3 months."])},
      "BMIMessage22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Endocrinology report and glycated hemoglobin, dated within the last 3 months."])},
      "BMIMessage3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot access the installations of Sociedad Minera Cerro Verde"])},
      "NoCoca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I declare that I do not consume coca, coca leaves, or their derivatives."])},
      "NoMarijuana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I declare that I do not consume marijuana, cannabis oil, and/or any other derivatives thereof."])},
      "PresentationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
      "PresentationBody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the International SOS Platform. In order to enter as a visitor to the facilities of Sociedad Minera Cerro Verde S.A.A. located in Arequipa – Peru, you must meet certain mandatory health requirements, which are described in the Occupational Health and Safety Regulations for Mining (Supreme Decree No. 024-2016-EM and its amendments)."])},
      "PresentationBody2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that the information submitted will be reviewed by the  Health physicians of International SOS and compared with the medical requirements, therefore it has the character of an Affidavit."])},
      "PresentationBody3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you mark 'Yes' in the affidavit, for your health and safety, you must refrain from continuing with the visit process."])},
      "PresentationBody4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your cooperation."])},
      "ClickToViewPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to view the file"])}
    },
    "Fields": {
      "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "LastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
      "SecondLastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Last Name"])},
      "Birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthdate"])},
      "TypeIDDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Visitor's ID Document"])},
      "IDDN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitor's ID Document Number"])},
      "Relationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Visitor"])},
      "Insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of Medical Insurance the Visitor Has"])},
      "HealthCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic or Health Center Where the Visitor Should Be Taken in Case of Emergency"])},
      "Specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify"])},
      "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the Company"])},
      "Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity to be carried out"])},
      "Mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
      "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact in case of Emergency"])},
      "CountryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Code"])},
      "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family Member's Phone in Case of Emergency"])},
      "EmergencyContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency Contact's Full Name"])},
      "RecentSurgery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent major surgery"])},
      "Disorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disorders of coagulation, thrombosis, others"])},
      "Diabetes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diabetes mellitus"])},
      "Hypertension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypertension (High blood pressure)"])},
      "Pregnancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregnancy"])},
      "NeurologicalDisorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neurological Disorders: Seizures, epilepsy, vertigo, others"])},
      "RecentInfections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent infections (mild or severe)"])},
      "Obesity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obesity"])},
      "CardiacProblems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardiac problems: pacemaker, coronary heart disease, others"])},
      "RespiratoryDisorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respiratory disorders: asthma, COPD, others"])},
      "OphthalmologicalProblems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ophthalmological problems: retinopathy, glaucoma, others"])},
      "DigestiveProblems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digestive problems: digestive bleeding, hepatitis, cirrhosis, others"])},
      "SleepApnea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleep apnea"])},
      "Allergies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergies"])},
      "AnotherMedicalCondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another medical condition"])},
      "AnotherMedicalConditionSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not indicated in the above list"])},
      "RecentSurgerySub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head, neck, chest or some abdominal surgery in the last 3 months."])},
      "DisordersSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alteration in coagulation, the consequence may be increased clot formation or bleeding that is difficult to control."])},
      "DiabetesSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disease in which it is difficult to control the level of glucose in the blood. Without control it can affect important organs such as: heart, brain, kidneys, eyes, among others."])},
      "HypertensionSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The heart works harder as it encounters stiff blood vessels and the organs receive less oxygen. There may be a rupture of a cerebral blood vessel, kidney damage, etc"])},
      "PregnancySub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period when a fetus develops inside a woman's uterus."])},
      "NeurologicalDisordersSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epilepsy, vertigo, others: Vertigo: It is the feeling of instability when walking, which disappears when sitting or lying down."])},
      "RecentInfectionsSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry, development and multiplication of an infectious agent in a person's body."])},
      "CardiacProblemsSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pacemaker, coronary artery disease, others: Refers to the person having a history of heart disease, for example: use of a cardiac pacemaker, pathology of the coronary arteries (coronary artery disease)"])},
      "RespiratoryDisordersSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asthma, COPD, others: This means that the person has a history of chronic respiratory diseases, for example, asthma, chronic obstructive pulmonary disease (COPD)."])},
      "OphthalmologicalProblemsSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retinopathy, glaucoma, others: Refers to chronic eye diseases, alteration of blood vessels, nerves or other structures of the eye that can cause blindness"])},
      "DigestiveProblemsSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digestive bleeding, hepatitis, cirrhosis, others: Refers to diseases of the chronic digestive system (liver cirrhosis), esophageal ulcers or varicose veins can cause digestive bleeding, for example"])},
      "SleepApneaSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This refers to problems with breathing while sleeping. This disease can cause drowsiness and fatigue if it is not controlled."])},
      "AllergiesSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is important to detail any allergy, be it food or medication, to keep a record of it and warn if any related event occurs during your visit."])},
      "RecentSurgeryFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surgery Report indicating date and surgical procedure performed."])},
      "DisordersFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hematology or Internal Medicine Report, Laboratory Tests: coagulation tests."])},
      "DiabetesFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endocrinology Report, Laboratory Tests: Blood glucose test and glycated hemoglobin (HbA1c)."])},
      "HypertensionFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardiology Report: informing updated treatment."])},
      "PregnancyFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obstetrics Report, informing weeks of gestation."])},
      "NeurologicalDisordersFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neurology service report."])},
      "RecentInfectionsFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated report from the treating physician, indicating the treatment received."])},
      "CardiacProblemsFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardiology Report."])},
      "RespiratoryDisordersFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulmonology Report."])},
      "OphthalmologicalProblemsFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ophthalmology Report."])},
      "DigestiveProblemsFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digestive doctor's report, Laboratory Tests: blood count with hematocrit."])},
      "SleepApneaFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulmonology Report."])},
      "AllergiesFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report from the treating doctor or the Allergist doctor."])},
      "BloodPressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blood pressure"])},
      "OxygenSaturation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oxygen saturation"])},
      "Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
      "HeartRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heart rate"])},
      "RespiratoryRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respiratory rate"])},
      "Tall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
      "BMI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BMI"])},
      "SelectUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Metric System"])},
      "ShowMedication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail current medication"])},
      "RecentSurgeryFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Surgery Report indicating date and surgical procedure performed, updated within 3 months of the procedure."])},
      "RecentSurgeryFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratory Tests: blood count with hematocrit, dated within 3 months."])},
      "RecentSurgeryFiles3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated prescription if still taking medication."])},
      "DisordersFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach report from Hematology or Internal Medicine or Vascular Surgery or Neurosurgery, updated within 3 months."])},
      "DisordersFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratory Tests: coagulation tests, dated within 3 months."])},
      "DisordersFiles3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated prescription with current medication."])},
      "DiabetesFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Endocrinology Report, dated within the last 3 months."])},
      "DiabetesFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratory Tests: Blood glucose test and glycated hemoglobin (HbA1c), dated within 3 months."])},
      "DiabetesFiles3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated prescription with current medication."])},
      "HypertensionFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Cardiology Report, dated within the last 3 months."])},
      "HypertensionFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated prescription with current medication."])},
      "PregnancyFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Obstetrics Report, informing weeks of gestation, dated within 4 weeks, indicating ability to travel to altitudes above 2500 meters."])},
      "NeurologicalDisordersFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Neurology service report, dated within 3 months."])},
      "NeurologicalDisordersFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated prescription with current medication."])},
      "RecentInfectionsFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach updated report from the treating physician, dated within 1 month, indicating the treatment received."])},
      "RecentInfectionsFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated prescription with current medication."])},
      "CardiacProblemsFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Cardiology Report, dated within 1 month, indicating presence or absence of pacemaker, and procedures performed."])},
      "CardiacProblemsFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Stress Test Report, dated within 1 month."])},
      "CardiacProblemsFiles3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated prescription with current medication."])},
      "RespiratoryDisordersFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Pulmonology Report, dated within 1 month."])},
      "RespiratoryDisordersFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spirometry test report, dated within 1 month."])},
      "RespiratoryDisordersFiles3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated prescription with current medication."])},
      "OphthalmologicalProblemsFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Ophthalmology Report, dated within 1 month."])},
      "OphthalmologicalProblemsFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated prescription with current medication."])},
      "DigestiveProblemsFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach updated Digestive doctor's report, dated within 1 month."])},
      "DigestiveProblemsFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratory Tests: blood count with hematocrit, dated within 1 month."])},
      "DigestiveProblemsFiles3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated prescription with current medication."])},
      "SleepApneaFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Pulmonology Report, dated within 3 months."])},
      "AllergyDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify which:"])},
      "OtherConditionsFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add prescription for any other medication being taken."])},
      "IMCFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardiology Report and Stress Test, dated within the last 3 months."])},
      "IMCFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endocrinology Report and Glycated Hemoglobin, dated within the last 3 months."])},
      "CompanyHeadquarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site"])},
      "SwornDeclaration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sworn Statement"])},
      "AuthorizingParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of Parent, Guardian, or Legal Representative Authorizing the Visit"])}
    },
    "Buttons": {
      "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["submit"])},
      "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])},
      "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back"])},
      "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save"])},
      "Done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])}
    },
    "Errors": {
      "NameValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field Name is required"])},
      "LastNameValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field Last Name is required"])},
      "SecondLastNameValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field Second Last Name is required"])},
      "BirthDateValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field Birthdate is required"])},
      "TypeIDDocumentValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field Type ID Document is required"])},
      "IDDNValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field ID Document Number is required"])},
      "CompanyValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field Name of the Company and names is required"])},
      "EmailValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field Email and names is required"])},
      "EmailValidationFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Email field does not meet the required format"])},
      "ActivityValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field Activity to be carried out is required"])},
      "ContactValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field Contact in case of Emergency is required"])},
      "PhoneValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field Telephone of Contact is required"])},
      "CountryCodeValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field Country Code is required"])},
      "WeightValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field Weight is required"])},
      "TallValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field Tall is required"])},
      "AnotherMedicalConditionValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field Another Medical Condition is required"])},
      "AtttachDocumentValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field Attach Document is required"])},
      "DeclarationRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Field Declaration is Required"])},
      "BMIOutOfRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BMI is not within range"])},
      "InvalidCharacter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Character"])},
      "DNIValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The DNI must have 8 characters"])},
      "RequiredSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select at least one option."])},
      "SelectOptionValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe Seleccionar al menos una opción."])}
    },
    "ToolTip": {
      "Theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Visualization Mode"])}
    },
    "Hints": {
      "Tall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For example : 1.78"])},
      "Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For example : 78"])},
      "ObseityAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value has been marked due to the provided weight and height values"])}
    }
  }
}