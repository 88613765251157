export default {
  "app": {
    "AppBar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["formato de visita"])},
    "LanguajeButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["idioma"])},
    "GroupLabels": {
      "SurgeryAndPregnancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cirugía o Embarazo"])},
      "Group1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desorden de la coagulación, trombosis, diabetes, hipertensión arterial o epilepsia"])},
      "Group2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problemas del corazón, pulmones, ojos o sistema digestivo"])},
      "Group3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infecciones recientes (de moderadas a severas), problemas del sueño o alergias"])}
    },
    "Labels": {
      "PersonalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información Personal"])},
      "MedInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información Medica"])},
      "MedicalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El (la) paciente ha presentado en los últimos 6 meses lo siguiente"])},
      "CurrentMedication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uso de medicación actual"])},
      "Attach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjuntar Documentos"])},
      "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])},
      "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "DataSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacion guardada Correctamente"])},
      "MessageDataSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos seran enviados al personal medico de International SOS"])},
      "DataAuthorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorización sobre datos personales"])},
      "DataConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizo el tratamiento de mis datos personales y datos personales sensibles por Sociedad Minera Cerro Verde S.A.A. e International SOS y conozco que puedo ejercer mis derechos de acceso, rectificación, cancelación y oposición en cualquier momento, enviando un correo electrónico a SM-CVE-datospersonales", "@", "fmi.com y DL.SUP.ASIS", "@", "international.com. Para cualquier información adicional, consultar la política de privacidad cargada en nuestro sitio web https://www.cerroverde.pe y en el siguiente "])},
      "AtttachDocumentMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificamos que selecciono una condición que amerita una evaluación por el área médica de Sermedi, para lo cual requerimos pueda adjuntar el informe médico actualizado o evidencia de tratamiento médico (puede ser una foto)."])},
      "Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enlace"])},
      "SwornStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La información declarada en este cuestionario será considerada como declaración jurada."])},
      "BMI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indice de Masa Corporal"])},
      "BMIMessage1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin observación relacionado con obesidad."])},
      "BMIMessage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar obligatoriamente informes médicos para revisión por el Área Médica:"])},
      "BMIMessage21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Informe de Cardiología y Prueba de esfuerzo , con 3 meses de antiguedad."])},
      "BMIMessage22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Informe de Endocrinología y hemoglobina glicosilada, con 3 meses de antiguedad"])},
      "BMIMessage3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede ingresar a las instalaciones de Sociedad Minera Cerro Verde."])},
      "NoCoca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaro que no consumo coca, hoja de coca y sus derivados."])},
      "NoMarijuana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaro que no consumo marihuana, aceite de cannabis y/o cualquier otro de sus derivados."])},
      "PresentationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido"])},
      "PresentationBody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido a la Plataforma de International SOS. Para poder ingresar como visitante a las instalaciones de Sociedad Minera Cerro verde S.A.A. ubicadas en Arequipa – Perú usted deberá cumplir con ciertos requisitos de salud obligatorios los cuales se encuentran descritos en el Reglamento de Seguridad y Salud Ocupacional para Minería (D.S. 024-2016-EM y sus modificatorias).​"])},
      "PresentationBody2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tome en cuenta que la información enviada será evaluada por parte de los médicos de International SOS y contrastada con los requisitos médicos por lo que tiene carácter de Declaración Jurada."])},
      "PresentationBody3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En caso de marcar en la declaración jurada como respuesta “Si”, por su salud y seguridad debe abstenerse de continuar con el proceso de visita."])},
      "PresentationBody4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias por su Colaboración."])},
      "ClickToViewPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga click para ver el Archivo"])}
    },
    "Fields": {
      "FullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellidos y Nombres"])},
      "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombres"])},
      "LastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido Paterno"])},
      "SecondLastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido Materno"])},
      "Birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de Nacimiento"])},
      "TypeIDDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Documento del Visitante"])},
      "IDDN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero de Documento de Identidad del Visitante"])},
      "Relationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Visitante"])},
      "Insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Seguro Medico con el que cuenta el visitante"])},
      "HealthCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinica o Centro de Salud donde debe ser trasladado el visitante en caso de Emergencia"])},
      "Specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Especifique"])},
      "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa"])},
      "Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad a Realizar"])},
      "Mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo Electronico"])},
      "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto en caso de Emergencia"])},
      "CountryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codigo de Pais"])},
      "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono de Familiar en caso de Emergencia"])},
      "EmergencyContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre y Apellido del Contacto en caso de Emergencia"])},
      "RecentSurgery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cirugia Mayor Reciente"])},
      "Disorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desórdenes de la coagulación, trombosis, otros"])},
      "Diabetes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diabetes Mellitus"])},
      "Hypertension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hipertensión arterial"])},
      "Pregnancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embarazo"])},
      "NeurologicalDisorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problemas neurológicos: epilepsia, vértigos, otros"])},
      "RecentInfections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infecciones recientes (de moderadas a severas)"])},
      "Obesity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obesidad"])},
      "CardiacProblems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problemas cardiacos: marcapasos, coronariopatía, otros"])},
      "RespiratoryDisorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problemas respiratorios: asma, EPOC, otros"])},
      "OphthalmologicalProblems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problemas oftalmológicos: retinopatía, glaucoma, otros"])},
      "DigestiveProblems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problemas digestivos: sangrado digestivo, hepatitis, cirrosis, otros"])},
      "SleepApnea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apnea del sueño"])},
      "Allergies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alergias"])},
      "Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso"])},
      "Tall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talla"])},
      "BMI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMC"])},
      "SelectUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione Sistema Metrico"])},
      "RecentSurgerySub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabeza, cuello, tórax o alguna cirugía abdominal en los últimos 3 meses."])},
      "DisordersSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alteración en la coagulación, la consecuencia puede ser aumento de la formación de coágulos o sangrado de difícil control."])},
      "DiabetesSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfermedad en la que resulta difícil controlar el nivel de glucosa en sangre. Sin control puede afectar órganos importantes como: corazón, cerebro, riñones, ojos, entre otros."])},
      "HypertensionSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El corazón trabaja más porque encuentra vasos sanguíneos rígidos y los órganos reciben menos oxígeno. Puede haber rotura de un vaso sanguíneo cerebral, daño renal, etc."])},
      "PregnancySub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período en el que se desarrolla un feto dentro del útero de la mujer."])},
      "NeurologicalDisordersSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epilepsia, vértigo, otros: Vértigo: Es la sensación de inestabilidad al caminar, que desaparece al sentarse o acostarse."])},
      "RecentInfectionsSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrada, desarrollo y multiplicación de un agente infeccioso en el organismo de una persona."])},
      "CardiacProblemsSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcapasos, enfermedad de las arterias coronarias, otras: Se refiere a que la persona tiene antecedentes de enfermedades del corazón, por ejemplo: uso de marcapasos cardíaco, patología de las arterias coronarias (enfermedad de las arterias coronarias)."])},
      "RespiratoryDisordersSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asma, EPOC, otros: Esto significa que la persona tiene antecedentes de enfermedades respiratorias crónicas, por ejemplo, asma, enfermedad pulmonar obstructiva crónica (EPOC)."])},
      "OphthalmologicalProblemsSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retinopatía, glaucoma, otros: Se refiere a enfermedades oculares crónicas, alteración de los vasos sanguíneos, nervios u otras estructuras del ojo que pueden provocar ceguera."])},
      "DigestiveProblemsSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sangrado digestivo, hepatitis, cirrosis, otros: Se refiere a enfermedades del sistema digestivo crónico (cirrosis hepática), úlceras esofágicas o varices pueden provocar sangrado digestivo, por ejemplo."])},
      "SleepApneaSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se refiere a problemas para respirar mientras se duerme. Esta enfermedad puede provocar somnolencia y fatiga si no se controla"])},
      "AllergiesSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es importante detallar cualquier alergia, ya sea a alimentos o medicamentos, para llevar un registro de esta y avisar si ocurre algún evento relacionado durante su visita."])},
      "AnotherMedicalCondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otra condición médica importante:"])},
      "AnotherMedicalConditionSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No indicadas en la relación anterior"])},
      "RecentSurgeryFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar Informe de Cirugía indicando fecha y procedimiento quirúrgico realizado, actualizado con fecha no mayor a 3 meses desde el procedimiento."])},
      "RecentSurgeryFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exámenes de Laboratorio: hemograma con hematocrito, con fecha no mayor a 3 meses."])},
      "RecentSurgeryFiles3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receta actualizada en caso de continuar tomando medicación."])},
      "DisordersFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar informe de Hematología o Medicina Interna o Cirugía Vascular o Neurocirugía, actualizado con fecha no mayor a 3 meses."])},
      "DisordersFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pruebas de Laboratorio: pruebas de coagulación, con fecha no mayor a 3 meses."])},
      "DisordersFiles3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receta con medicación actualizada."])},
      "DiabetesFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar Informe de Endocrinología, con 3 meses de antiguedad."])},
      "DiabetesFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exámenes de Laboratorio: Examen de glucemia y hemoglobina glucosilada (HbA1c), con fecha no mayor a 3 meses."])},
      "DiabetesFiles3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receta con medicación actualizada."])},
      "HypertensionFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar Informe de Cardiología, con 3 meses de antiguedad."])},
      "HypertensionFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receta con medicación actualizada."])},
      "PregnancyFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar Informe de Obstetricia, informando semanas de gestación, con fecha no mayor a 4 semanas, con indicación de que puede viajar a altura mayor a 2500 msnm."])},
      "NeurologicalDisordersFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar Informe del servicio de Neurología, con fecha no mayor a 3 meses."])},
      "NeurologicalDisordersFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receta con medicación actualizada."])},
      "RecentInfectionsFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deberá adjuntar Informe actualizado del médico tratante, con fecha no mayor a 1 mes, indicando el tratamiento recibido."])},
      "RecentInfectionsFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receta con medicación actualizada."])},
      "CardiacProblemsFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar Informe de Cardiología, con fecha no mayor a 1 mes, indicando la presencia o ausencia de marcapasos, y los procedimientos realizados."])},
      "CardiacProblemsFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe de Prueba de Esfuerzo, con fecha no mayor a 1 mes."])},
      "CardiacProblemsFiles3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receta con medicación actualizada."])},
      "RespiratoryDisordersFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar Informe de Neumología, con fecha no mayor a 1 mes."])},
      "RespiratoryDisordersFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prueba de Espirometría con fecha no mayor a 1 mes."])},
      "RespiratoryDisordersFiles3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receta con medicación actualizada."])},
      "OphthalmologicalProblemsFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar informe de Oftalmología con fecha no mayor a 1 mes."])},
      "OphthalmologicalProblemsFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receta con medicación actualizada."])},
      "DigestiveProblemsFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar informe médico digestivo actualizado con fecha no mayor a 1 mes."])},
      "DigestiveProblemsFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exámenes de Laboratorio: hemograma con hematocrito, con fecha no mayor a 1 mes."])},
      "DigestiveProblemsFiles3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receta con medicación actualizada."])},
      "SleepApneaFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar Informe de Neumología, con fecha no mayor a 3 meses."])},
      "AllergyDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indique cual:"])},
      "OtherConditionsFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añada la receta de cualquier otro medicamento que este tomando."])},
      "IMCFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe de Cardiología y Prueba de esfuerzo, con 3 meses de antiguedad."])},
      "IMCFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe de Endocrinología y hemoglobina glicosilada, con 3 meses de antiguedad."])},
      "CompanyHeadquarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio"])},
      "SwornDeclaration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaracion Jurada"])},
      "AuthorizingParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de Padre , Madre o Apoderado que autoriza la visita"])}
    },
    "Buttons": {
      "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enviar"])},
      "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["siguiente"])},
      "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["atras"])},
      "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
      "Done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hecho"])}
    },
    "Errors": {
      "NameValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Campo Nombre es requerido"])},
      "LastNameValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Campo Apellido Paterno es requerido"])},
      "SecondLastNameValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Campo NApellido Materno es requerido"])},
      "BirthDateValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Fecha de Nacimiento es requerido"])},
      "TypeIDDocumentValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Tipo de Documento es requerido"])},
      "IDDNValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Documento de Identidad es requerido"])},
      "CompanyValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Empresa es requerido"])},
      "EmailValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Email es requerido"])},
      "EmailValidationFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Email no cumple con el formato requerido"])},
      "ActivityValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Acividad a Realizar es requerido"])},
      "ContactValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Contacto en caso de Emergencia es requerido"])},
      "PhoneValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Telefono de Contacto es requerido"])},
      "CountryCodeValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Codigo de Pais es requerido"])},
      "WeightValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Peso es requerido"])},
      "TallValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Talla es requerido"])},
      "AnotherMedicalConditionValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Otra Condición Medica es requerido"])},
      "AtttachDocumentValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Adjuntar Archivo es requerido"])},
      "DeclarationRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo de Declaracion es requerido"])},
      "BMIOutOfRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El imc no esta en el rango aceptable"])},
      "InvalidCharacter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caracter Invalido"])},
      "DNIValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El DNI debe tener 8 caracteres"])},
      "SelectOptionValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe Seleccionar al menos una opción."])},
      "RequiredSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe Seleccionar al menos una opción."])}
    },
    "ToolTip": {
      "Theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar Modo Visualizacion"])}
    },
    "Hints": {
      "Tall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por Ejemplo : 1.78"])},
      "Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por ejemplo : 78"])},
      "ObseityAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este valor ha sido marcado debido a los valores proporcionados de peso y talla"])}
    }
  }
}