<template>
    <v-card variant="text" class="mt-4">
        <v-form ref="form" v-model="formValid" @submit.prevent="next">
            <v-card-text>
                <p class="text-center text-h5">
                    {{ $t('app.Labels.MedInformation').toUpperCase() }}
                </p>
                <p class="my-4 text-medium-emphasis">
                    {{ $t('app.Labels.MedicalInformation') }}
                </p>

                <!-- Grupo 0: Cirugía mayor reciente y Embarazo -->
                <v-card outlined class="mb-4">
                    <v-card-text>
                        <v-row class="d-flex align-center justify-space-between">
                            <v-col cols="12" md="8">
                                <p class="font-weight-bold mb-4">
                                    {{ $t('app.GroupLabels.SurgeryAndPregnancy') }}
                                </p>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-radio-group v-model="groupSurgeryPregnancy"
                                    :rules="[v => (v === true || v === false) ? true : $t('app.Errors.SelectOptionValidation')]"
                                    inline>
                                    <v-radio :label="$t('app.Labels.Yes')" :value="true" />
                                    <v-radio :label="$t('app.Labels.No')" :value="false" />
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-expand-transition>
                            <div v-if="groupSurgeryPregnancy" class="ml-4">
                                <template v-for="field in group0Fields" :key="field.id">
                                    <v-row>
                                        <v-col class="my-auto" cols="8" md="8">
                                            <div class="font-weight-medium">
                                                {{ $t(field.label) }}
                                            </div>
                                            <div class="text-grey text-caption">
                                                {{ $t(field.subLabel) }}
                                            </div>
                                        </v-col>
                                        <v-col class="my-auto" cols="4" md="4">
                                            <v-radio-group v-model="model[field.id]"
                                                :rules="[v => (v === true || v === false) ? true : $t('app.Errors.SelectOptionValidation')]"
                                                inline>
                                                <v-radio :label="$t('app.Labels.Yes')" :value="true" />
                                                <v-radio :label="$t('app.Labels.No')" :value="false" />
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                    <!-- Mostrar inputs de archivos solo si la respuesta del campo es 'Sí' -->
                                    <v-row v-if="model[field.id] === true">
                                        <template v-if="field.id === '1'">
                                            <template v-for="fileLabel in recentSurgeryFiles" :key="fileLabel">
                                                <v-col class="my-auto" cols="12" md="4">
                                                    <div class="text-grey text-caption">
                                                        {{ $t('app.Fields.' + fileLabel) }}
                                                    </div>
                                                    <v-file-input
                                                        :rules="[v => !!v.length || $t('app.Errors.AtttachDocumentValidation')]"
                                                        label="Archivos" v-model="documentos[fileLabel]" multiple
                                                        @change="updateDocumentos(fileLabel, documentos[fileLabel])" />
                                                </v-col>
                                            </template>
                                        </template>
                                        <template v-if="field.id === '5'">
                                            <template v-for="fileLabel in pregnancyFiles" :key="fileLabel">
                                                <v-col class="my-auto" cols="12" md="4">
                                                    <div class="text-grey text-caption">
                                                        {{ $t('app.Fields.' + fileLabel) }}
                                                    </div>
                                                    <v-file-input
                                                        :rules="[v => !!v.length || $t('app.Errors.AtttachDocumentValidation')]"
                                                        label="Archivos" v-model="documentos[fileLabel]" multiple
                                                        @change="updateDocumentos(fileLabel, documentos[fileLabel])" />
                                                </v-col>
                                            </template>
                                        </template>
                                    </v-row>
                                </template>
                            </div>
                        </v-expand-transition>
                    </v-card-text>
                </v-card>

                <!-- Grupo 1: Desorden de la coagulación, trombosis, diabetes, hipertensión arterial o epilepsia -->
                <v-card outlined class="mb-4">

                    <v-card-text>
                        <v-row class="d-flex align-center justify-space-between">
                            <v-col cols="12" md="8">
                                <p class="font-weight-bold"> {{ $t('app.GroupLabels.Group1') }}</p>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-radio-group v-model="group1"
                                    :rules="[v => (v === true || v === false) ? true : $t('app.Errors.SelectOptionValidation')]"
                                    inline>
                                    <v-radio :label="$t('app.Labels.Yes')" :value="true" />
                                    <v-radio :label="$t('app.Labels.No')" :value="false" />
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-expand-transition>
                            <div v-if="group1" class="ml-4">
                                <template v-for="field in group1Fields" :key="field.id">
                                    <v-row>
                                        <v-col class="my-auto" cols="8" md="8">
                                            <div class="font-weight-medium">
                                                {{ $t(field.label) }}
                                            </div>
                                            <div class="text-grey text-caption">
                                                {{ $t(field.subLabel) }}
                                            </div>
                                        </v-col>
                                        <v-col class="my-auto" cols="4" md="4">
                                            <v-radio-group v-model="model[field.id]"
                                                :rules="[v => (v === true || v === false) ? true : $t('app.Errors.SelectOptionValidation')]"
                                                inline>
                                                <v-radio :label="$t('app.Labels.Yes')" :value="true" />
                                                <v-radio :label="$t('app.Labels.No')" :value="false" />
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="model[field.id] === true">
                                        <template v-for="fileLabel in field.fileLabels" :key="fileLabel">
                                            <v-col class="my-auto" cols="12" md="4">
                                                <div class="text-grey text-caption">
                                                    {{ $t('app.Fields.' + fileLabel) }}
                                                </div>
                                                <v-file-input
                                                    :rules="[v => !!v.length || $t('app.Errors.AtttachDocumentValidation')]"
                                                    label="Archivos" v-model="documentos[fileLabel]" multiple
                                                    @change="updateDocumentos(fileLabel, documentos[fileLabel])" />
                                            </v-col>
                                        </template>
                                    </v-row>
                                </template>
                            </div>
                        </v-expand-transition>
                    </v-card-text>
                </v-card>

                <!-- Grupo 2: Problemas del corazón, pulmones, ojos o sistema digestivo -->
                <v-card outlined class="mb-4">
                    <v-card-text>
                        <v-row class="d-flex align-center justify-space-between">
                            <v-col cols="12" md="8">
                                <p class="font-weight-bold">{{ $t('app.GroupLabels.Group2') }}</p>                                
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-radio-group v-model="group2"
                                    :rules="[v => (v === true || v === false) ? true : $t('app.Errors.SelectOptionValidation')]"
                                    inline>
                                    <v-radio :label="$t('app.Labels.Yes')" :value="true" />
                                    <v-radio :label="$t('app.Labels.No')" :value="false" />
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-expand-transition>
                            <div v-if="group2" class="ml-4">
                                <template v-for="field in group2Fields" :key="field.id">
                                    <v-row>
                                        <v-col class="my-auto" cols="8" md="8">
                                            <div class="font-weight-medium">
                                                {{ $t(field.label) }}
                                            </div>
                                            <div class="text-grey text-caption">
                                                {{ $t(field.subLabel) }}
                                            </div>
                                        </v-col>
                                        <v-col class="my-auto" cols="4" md="4">
                                            <v-radio-group v-model="model[field.id]"
                                                :rules="[v => (v === true || v === false) ? true : $t('app.Errors.SelectOptionValidation')]"
                                                inline>
                                                <v-radio :label="$t('app.Labels.Yes')" :value="true" />
                                                <v-radio :label="$t('app.Labels.No')" :value="false" />
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="model[field.id] === true">
                                        <template v-for="fileLabel in field.fileLabels" :key="fileLabel">
                                            <v-col class="my-auto" cols="12" md="4">
                                                <div class="text-grey text-caption">
                                                    {{ $t('app.Fields.' + fileLabel) }}
                                                </div>
                                                <v-file-input
                                                    :rules="[v => !!v.length || $t('app.Errors.AtttachDocumentValidation')]"
                                                    label="Archivos" v-model="documentos[fileLabel]" multiple
                                                    @change="updateDocumentos(fileLabel, documentos[fileLabel])" />
                                            </v-col>
                                        </template>
                                    </v-row>
                                </template>
                            </div>
                        </v-expand-transition>
                    </v-card-text>
                </v-card>

                <!-- Grupo 3: Infecciones recientes, problemas del sueño o alergias -->
                <v-card outlined class="mb-4">
                    <v-card-text>
                        <v-row class="d-flex align-center justify-space-between">
                            <v-col cols="12" md="8">
                                <p class="font-weight-bold">{{ $t('app.GroupLabels.Group3') }}</p>                                
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-radio-group v-model="group3"
                                    :rules="[v => (v === true || v === false) ? true : $t('app.Errors.SelectOptionValidation')]"
                                    inline>
                                    <v-radio :label="$t('app.Labels.Yes')" :value="true" />
                                    <v-radio :label="$t('app.Labels.No')" :value="false" />
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-expand-transition>
                            <div v-if="group3" class="ml-4">
                                <template v-for="field in group3Fields" :key="field.id">
                                    <v-row>
                                        <v-col class="my-auto" cols="8" md="8">
                                            <div class="font-weight-medium">
                                                {{ $t(field.label) }}
                                            </div>
                                            <div class="text-grey text-caption">
                                                {{ $t(field.subLabel) }}
                                            </div>
                                        </v-col>
                                        <v-col class="my-auto" cols="4" md="4">
                                            <v-radio-group v-model="model[field.id]"
                                                :rules="[v => (v === true || v === false) ? true : $t('app.Errors.SelectOptionValidation')]"
                                                inline>
                                                <v-radio :label="$t('app.Labels.Yes')" :value="true" />
                                                <v-radio :label="$t('app.Labels.No')" :value="false" />
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="model[field.id] === true">
                                        <template v-if="field.id === '14'">
                                            <v-col class="my-auto" cols="12">
                                                <v-text-field v-model="alergias" :rules="[
                                                    v => !!v.length || $t('app.Errors.AtttachDocumentValidation'),
                                                    v => !v.includes('-') || $t('app.Errors.InvalidCharacter')
                                                ]" :label="$t('app.Fields.AllergyDetails')" type="text" pattern=".*" />
                                            </v-col>
                                        </template>
                                        <template v-else>
                                            <template v-for="fileLabel in field.fileLabels" :key="fileLabel">
                                                <v-col class="my-auto" cols="12" md="4">
                                                    <div class="text-grey text-caption">
                                                        {{ $t('app.Fields.' + fileLabel) }}
                                                    </div>
                                                    <v-file-input
                                                        :rules="[v => !!v.length || $t('app.Errors.AtttachDocumentValidation')]"
                                                        label="Archivos" v-model="documentos[fileLabel]" multiple
                                                        @change="updateDocumentos(fileLabel, documentos[fileLabel])" />
                                                </v-col>
                                            </template>
                                        </template>
                                    </v-row>
                                </template>
                            </div>
                        </v-expand-transition>
                    </v-card-text>
                </v-card>

                <!-- Grupo 4: Otra condición médica importante -->
                <v-card outlined class="mb-4">

                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="8">
                                <p class="font-weight-bold">
                                    {{ $t('app.Fields.AnotherMedicalCondition') }}
                                </p>
                                <div class="text-grey text-caption">
                                    {{ $t('app.Fields.AnotherMedicalConditionSub') }}
                                </div>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-radio-group v-model="model['15']"
                                    :rules="[v => (v === true || v === false) ? true : $t('app.Errors.SelectOptionValidation')]"
                                    inline>
                                    <v-radio :label="$t('app.Labels.Yes')" :value="true" />
                                    <v-radio :label="$t('app.Labels.No')" :value="false" />
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-expand-transition>
                            <div v-if="model['15'] === true" class="ml-4">
                                <v-text-field class="my-4" v-model="otros_pregunta_formato"
                                    :rules="[v => !!v || $t('app.Errors.AnotherMedicalConditionValidation')]"
                                    :label="$t('app.Fields.AnotherMedicalCondition')" />
                                <div class="text-grey text-caption">
                                    {{ $t('app.Fields.OtherConditionsFiles') }}
                                </div>
                                <v-file-input :rules="[v => !!v.length || $t('app.Errors.AtttachDocumentValidation')]"
                                    label="Archivos" v-model="documentos.OtherConditionsFiles" multiple
                                    @change="updateDocumentos('OtherConditionsFiles', documentos.OtherConditionsFiles)" />
                            </div>
                        </v-expand-transition>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-card-actions>
                <v-btn color="orange-darken-1" variant="flat" @click="atras" :text="$t('app.Buttons.Back')" />
                <v-spacer />
                <v-btn color="success" variant="flat" type="submit" :text="$t('app.Buttons.Next')" />
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
export default {
    name: 'MedInformationView',
    data: () => ({
        formValid: false,
        fields: [
            // Grupo 0
            { id: '1', label: 'app.Fields.RecentSurgery', subLabel: 'app.Fields.RecentSurgerySub', fileLabels: ['RecentSurgeryFiles1', 'RecentSurgeryFiles2', 'RecentSurgeryFiles3'] },
            { id: '5', label: 'app.Fields.Pregnancy', subLabel: 'app.Fields.PregnancySub', fileLabels: ['PregnancyFiles1'] },
            // Grupo 1
            { id: '2', label: 'app.Fields.Disorders', subLabel: 'app.Fields.DisordersSub', fileLabels: ['DisordersFiles1', 'DisordersFiles2', 'DisordersFiles3'] },
            { id: '3', label: 'app.Fields.Diabetes', subLabel: 'app.Fields.DiabetesSub', fileLabels: ['DiabetesFiles1', 'DiabetesFiles2', 'DiabetesFiles3'] },
            { id: '4', label: 'app.Fields.Hypertension', subLabel: 'app.Fields.HypertensionSub', fileLabels: ['HypertensionFiles1', 'HypertensionFiles2'] },
            { id: '6', label: 'app.Fields.NeurologicalDisorders', subLabel: 'app.Fields.NeurologicalDisordersSub', fileLabels: ['NeurologicalDisordersFiles1', 'NeurologicalDisordersFiles2'] },
            // Grupo 2
            { id: '9', label: 'app.Fields.CardiacProblems', subLabel: 'app.Fields.CardiacProblemsSub', fileLabels: ['CardiacProblemsFiles1', 'CardiacProblemsFiles2', 'CardiacProblemsFiles3'] },
            { id: '10', label: 'app.Fields.RespiratoryDisorders', subLabel: 'app.Fields.RespiratoryDisordersSub', fileLabels: ['RespiratoryDisordersFiles1', 'RespiratoryDisordersFiles2', 'RespiratoryDisordersFiles3'] },
            { id: '11', label: 'app.Fields.OphthalmologicalProblems', subLabel: 'app.Fields.OphthalmologicalProblemsSub', fileLabels: ['OphthalmologicalProblemsFiles1', 'OphthalmologicalProblemsFiles2'] },
            { id: '12', label: 'app.Fields.DigestiveProblems', subLabel: 'app.Fields.DigestiveProblemsSub', fileLabels: ['DigestiveProblemsFiles1', 'DigestiveProblemsFiles2', 'DigestiveProblemsFiles3'] },
            // Grupo 3
            { id: '7', label: 'app.Fields.RecentInfections', subLabel: 'app.Fields.RecentInfectionsSub', fileLabels: ['RecentInfectionsFiles1', 'RecentInfectionsFiles2'] },
            { id: '13', label: 'app.Fields.SleepApnea', subLabel: 'app.Fields.SleepApneaSub', fileLabels: ['SleepApneaFiles1'] },
            { id: '14', label: 'app.Fields.Allergies', subLabel: 'app.Fields.AllergiesSub', fileLabels: [] },
            // Grupo 4
            { id: '15', label: 'app.Fields.AnotherMedicalCondition', subLabel: 'app.Fields.AnotherMedicalConditionSub', fileLabels: ['OtherConditionsFiles'] }
        ]
    }),
    computed: {
        // Los grupos se almacenan por separado en el store
        groupSurgeryPregnancy: {
            get() {
                return this.$store.state.groupSurgeryPregnancy;
            },
            set(value) {
                this.$store.commit('SET_GROUP_SURGERY_PREGNANCY', value);
            }
        },
        group1: {
            get() {
                return this.$store.state.group1;
            },
            set(value) {
                this.$store.commit('SET_GROUP1', value);
            }
        },
        group2: {
            get() {
                return this.$store.state.group2;
            },
            set(value) {
                this.$store.commit('SET_GROUP2', value);
            }
        },
        group3: {
            get() {
                return this.$store.state.group3;
            },
            set(value) {
                this.$store.commit('SET_GROUP3', value);
            }
        },
        // Los datos médicos generales se mantienen en 'datos_medicos'
        model: {
            get() {
                return this.$store.state.datos_medicos || {};
            },
            set(newValue) {
                this.$store.commit('SET_DATOS_MEDICOS', newValue);
            }
        },
        documentos: {
            get() {
                return this.$store.state.documentos_adjuntos;
            },
            set(newValue) {
                for (let key in newValue) {
                    this.$store.commit(`SET_${key.toUpperCase()}`, newValue[key]);
                }
            }
        },
        alergias: {
            get() {
                return this.$store.state.alergias;
            },
            set(newValue) {
                this.$store.commit('SET_ALERGIAS', newValue);
            }
        },
        otros_pregunta_formato: {
            get() {
                return this.$store.state.otros_pregunta_formato || '';
            },
            set(newValue) {
                this.$store.commit('SET_OTROS_PREGUNTA_FORMATO', newValue);
            }
        },
        recentSurgeryFiles() {
            return ['RecentSurgeryFiles1', 'RecentSurgeryFiles2', 'RecentSurgeryFiles3'];
        },
        pregnancyFiles() {
            return ['PregnancyFiles1'];
        },
        group0Fields() {
            return this.fields.filter(field => ['1', '5'].includes(field.id));
        },
        group1Fields() {
            return this.fields.filter(field => ['2', '3', '4', '6'].includes(field.id));
        },
        group2Fields() {
            return this.fields.filter(field => ['9', '10', '11', '12'].includes(field.id));
        },
        group3Fields() {
            return this.fields.filter(field => ['7', '13', '14'].includes(field.id));
        }
    },
    methods: {
        next() {
            if (!this.$refs.form.validate()) {
                return;
            }
            let errores = [];

            // Validar que los grupos principales tengan una respuesta
            if (this.groupSurgeryPregnancy === null) {
                errores.push(this.$t('app.Errors.SelectOptionValidation') + ': ' + this.$t('app.GroupLabels.SurgeryAndPregnancy'));
            }
            if (this.group1 === null) {
                errores.push(this.$t('app.Errors.SelectOptionValidation') + ': ' + this.$t('app.GroupLabels.Group1'));
            }
            if (this.group2 === null) {
                errores.push(this.$t('app.Errors.SelectOptionValidation') + ': ' + this.$t('app.GroupLabels.Group2'));
            }
            if (this.group3 === null) {
                errores.push(this.$t('app.Errors.SelectOptionValidation') + ': ' + this.$t('app.GroupLabels.Group3'));
            }
            if (this.model['15'] === null) {
                errores.push(this.$t('app.Errors.SelectOptionValidation') + ': ' + this.$t('app.Fields.AnotherMedicalCondition'));
            }

            // Validar campos internos de cada grupo solo si el grupo está activo
            if (this.groupSurgeryPregnancy) {
                this.group0Fields.forEach(field => {
                    if (this.model[field.id] === undefined) {
                        errores.push(this.$t('app.Errors.SelectOptionValidation') + ': ' + this.$t(field.label));
                    } else if (this.model[field.id] === true) {
                        // Si se responde Sí, se requiere adjuntar archivos
                        if (field.fileLabels && field.fileLabels.length > 0) {
                            field.fileLabels.forEach(fileLabel => {
                                if (!this.documentos[fileLabel] || this.documentos[fileLabel].length === 0) {
                                    errores.push(this.$t('app.Errors.AtttachDocumentValidation') + ': ' + this.$t(field.label));
                                }
                            });
                        }
                        // Validación específica para alergias
                        if (field.id === '14' && (!this.alergias || this.alergias.trim() === '')) {
                            errores.push(this.$t('app.Errors.AtttachDocumentValidation') + ': ' + this.$t('app.Fields.AllergyDetails'));
                        }
                    }
                });
            }
            if (this.group1) {
                this.group1Fields.forEach(field => {
                    if (this.model[field.id] === undefined) {
                        errores.push(this.$t('app.Errors.SelectOptionValidation') + ': ' + this.$t(field.label));
                    } else if (this.model[field.id] === true) {
                        if (field.fileLabels && field.fileLabels.length > 0) {
                            field.fileLabels.forEach(fileLabel => {
                                if (!this.documentos[fileLabel] || this.documentos[fileLabel].length === 0) {
                                    errores.push(this.$t('app.Errors.AtttachDocumentValidation') + ': ' + this.$t(field.label));
                                }
                            });
                        }
                    }
                });
            }
            if (this.group2) {
                this.group2Fields.forEach(field => {
                    if (this.model[field.id] === undefined) {
                        errores.push(this.$t('app.Errors.SelectOptionValidation') + ': ' + this.$t(field.label));
                    } else if (this.model[field.id] === true) {
                        if (field.fileLabels && field.fileLabels.length > 0) {
                            field.fileLabels.forEach(fileLabel => {
                                if (!this.documentos[fileLabel] || this.documentos[fileLabel].length === 0) {
                                    errores.push(this.$t('app.Errors.AtttachDocumentValidation') + ': ' + this.$t(field.label));
                                }
                            });
                        }
                    }
                });
            }
            if (this.group3) {
                this.group3Fields.forEach(field => {
                    if (this.model[field.id] === undefined) {
                        errores.push(this.$t('app.Errors.SelectOptionValidation') + ': ' + this.$t(field.label));
                    } else if (this.model[field.id] === true) {
                        if (field.fileLabels && field.fileLabels.length > 0) {
                            field.fileLabels.forEach(fileLabel => {
                                if (!this.documentos[fileLabel] || this.documentos[fileLabel].length === 0) {
                                    errores.push(this.$t('app.Errors.AtttachDocumentValidation') + ': ' + this.$t(field.label));
                                }
                            });
                        }
                        if (field.id === '14' && (!this.alergias || this.alergias.trim() === '')) {
                            errores.push(this.$t('app.Errors.AtttachDocumentValidation') + ': ' + this.$t('app.Fields.AllergyDetails'));
                        }
                    }
                });
            }
            // Si existen errores, no se pasa a la siguiente pantalla
            if (errores.length > 0) {
                // Aquí puedes mostrar los errores en un diálogo o notificación
                return;
            }
            // Si todo es correcto, avanzamos a la siguiente ventana
            this.$store.commit('SET_VENTANA', 5);
        },
        atras() {
            this.$store.commit('SET_VENTANA', 3);
        },
        mostrarCampoOtros(value) {
            // Mostrar inputs de archivos solo si el valor es true
            return this.model[value] === true;
        },
        updateDocumentos(fileLabel, file) {
            this.$store.commit(`SET_${fileLabel.toUpperCase()}`, file);
        }
    }
};
</script>